import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-center flex-column-fluid p-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("img", {
      src: "/media/illustrations/sketchy-1/18.png",
      alt: "",
      class: "mw-100 mb-10 h-lg-450px"
    }, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("h1", {
      class: "fw-bold mb-10",
      style: {"color":"#a3a3c7"}
    }, "Seems there is nothing here", -1)),
    _createVNode(_component_router_link, {
      to: "/dashboard",
      class: "btn btn-primary"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Return Home")
      ])),
      _: 1
    })
  ]))
}